import React from 'react'
import {Helmet} from 'react-helmet';

import MixesRenderer from './../COMPONENTS/MixesRenderer.js';
import { sendPostRequest } from "./../HELPERS/requests.js"

/// REDUX ///
import * as ReduxActions from './../REDUX/functions.js';
import { bindActionCreators } from 'redux';
import {connect} from 'react-redux';

function mapStateToProps(state) {
    return {
        FADED_BODY: state.FADED_BODY
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}

class MixesSingle extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			TOTAL_MIXES: 0,
            MIXES: false,
            START: 0,
            LOADING: false,
            URL: props.QUERY,
            TITLE: false
		}
	}

	componentDidMount() {
		this.getMixes();
	}

	componentDidUpdate(prevProps, prevState, snapshot)
    {
        if (this.props.QUERY !== prevProps.QUERY) {
            this.setState({
				TOTAL_MIXES: 0,
	            MIXES: false,
	            LOADING: false,
	            URL: this.props.QUERY,
	            TITLE: false
            });

            let COMPONENT = this;
            setTimeout(function(){
                COMPONENT.getMixes()
            }, 50);
        }
    }

	getMixes = async() =>
	{
		if (!this.state.LOADING) {
			let START = this.state.START;	
	        
	        this.setState({LOADING: true});

	        if (typeof this.interval !== "undefined") {
		        clearInterval(this.interval);
		    }

	        try {
	            let SEND_DATA = {
	                action:     'getMixByUrl',
	                url: 		this.state.URL,
	                start:      START
	            };

	            let data = await sendPostRequest(SEND_DATA,'https://rm-da-funk.ru/api.php');

	            if (data !== false) {
	                this.setState({
	                    MIXES: data.mixes,
	                    LOADING: false,
	                    TOTAL_MIXES: data.total,
	                    TITLE: data.title
	                });
	                this.props.setActiveTitle(data.title);
	            } else {
	            	this.setState({
	                    MIXES: [],
	                    LOADING: false,
	                    TOTAL_MIXES: data.total,
	                    TITLE: data.title
	                });
	                this.props.setActiveTitle(data.title);
	            }
	        } catch (error) {
	            console.log(error);
	        }
	    }
	}

	getTitle()
	{
		if (this.state.TITLE) {
			return this.state.TITLE;
		} else {
			return this.state.URL;
		}
	}

	renderTitle()
	{
		return (
			<div className="head_title">
				<span>{this.getTitle()}</span>
			</div>
		);
	}

	renderMixes()
	{
		if (this.state.MIXES) {
			if (this.state.MIXES.length) {
				return (<MixesRenderer MIXES={this.state.MIXES} getMixes={this.getMixes} TOTAL_MIXES={this.state.TOTAL_MIXES} LOADING={this.state.LOADING} />);
			} else {
				return (<div className="no_mixes"><img src="/system_images/error.png" alt="error"/>Миксов нет!</div>);
			}
		} else {
			return (
				<div className="mixes_loader">
                	<img src="/system_images/loader.svg" alt="loader" />
                </div>
			);
		}
	}

	renderHelmet = () =>
	{
		let HELMET = '';
		if (this.state.MIXES.length) {
			let MIX = this.state.MIXES[0];
			HELMET = (
				<Helmet>
	                <title>RM DA FUNK - {MIX.title}</title>
				    <meta name="description" content={'RM DA FUNK - ' + MIX.title} data-react-helmet="true" />
				    <meta name="keywords" content={'RM DA FUNK - ' + MIX.title} data-react-helmet="true" />

				    <meta property="og:title" content={'RM DA FUNK - ' + MIX.title} data-react-helmet="true" />
				    <meta property="og:description" content={'RM DA FUNK - ' + MIX.title} data-react-helmet="true" />
				    <meta property="og:image" content={MIX.image} data-react-helmet="true" />
				    <meta property="og:type" content="article" data-react-helmet="true" />
				    <meta property="og:url" content= {MIX.url} data-react-helmet="true" />
				    <meta property="og:site_name" content="RM's Audio Spot" data-react-helmet="true" />
	            </Helmet>
            );
		} else {
			HELMET = (
				<Helmet>
                    <title>RM's Audio Spot</title>
				    <meta name="description" content="Музыка для своих от RM DA FUNK" data-react-helmet="true" />
				    <meta name="keywords" content="Музыка для своих от RM DA FUNK" data-react-helmet="true" />

				    <meta property="og:title" content="RM's Audio Spot" />
				    <meta property="og:description" content="Музыка для своих от RM DA FUNK" />
				    <meta property="og:image" content="https://rm-da-funk.ru/system_images/logo.png" />
				    <meta property="og:type" content="article" />
				    <meta property="og:url" content= "https://rm-da-funk.ru" />
				    <meta property="og:site_name" content="RM's Audio Spot" />
                </Helmet>
            );
		}

		return HELMET;
	}

	getClass = () =>
	{
		if (this.props.FADED_BODY) {
			return 'mixes_holder faded';
		} else {
			return 'mixes_holder';
		}
	}

	render() {
		
		
		return (
			<div id="mixes_list" className={this.getClass()}>
				{this.renderHelmet()}
				{this.renderTitle()}
				{this.renderMixes()}
			</div>
		)
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(MixesSingle)