import React from 'react';

import MixesListByGenre from './../COMPONENTS/MixesListByGenre.js';
import MixesListByYear from './../COMPONENTS/MixesListByYear.js';
import MixesSingle from './../COMPONENTS/MixesSingle.js';
import Header from './../COMPONENTS/Header.js';
import Loader from './../COMPONENTS/Loader.js';

export default class SubRoute extends React.Component {
	constructor(props)
    {
    	super(props);
		this.myRef = React.createRef();
	}

	scrollToTop = () =>
	{
		this.myRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
	}

	isNumber = (n) => 
	{
	    return typeof(n) != "boolean" && !isNaN(n);
	}

	renderContent(URL)
	{
		if (this.isNumber(URL)) {
			return <MixesListByYear QUERY={URL} />;
		} else if (URL.substring(0,10) === 'RM_DA_FUNK') {
			return <MixesSingle QUERY={URL} />;
		} else {
			return <MixesListByGenre QUERY={URL} />;
		}
	}

	render()
	{
		let URL = this.props.match.params.sub_route;

		return (
			<div ref={this.myRef}>
				<Loader />
				<Header URL={URL} scrollToTop={this.scrollToTop} />
				{this.renderContent(URL)}
			</div>
		);
	}
}
