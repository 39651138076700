import React from 'react'

export default class Donut extends React.Component {
	render()
	{
		return (
			<form method="POST" action="https://money.yandex.ru/quickpay/confirm.xml" target="_blank">
				<input type="hidden" name="receiver" value="410015288857399" />
				<input type="hidden" name="formcomment" value="d0nut" />
				<input type="hidden" name="short-dest" value="RM's Audio Spot Donut" />
				<input type="hidden" name="quickpay-form" value="donate" />
				<input type="hidden" name="sum" value="100.00" data-type="number" />
				<input type="hidden" name="comment" value="R U READY TO DONUT?" />
				<input type="hidden" name="need-fio" value="false" />
				<input type="hidden" name="need-email" value="false" />
				<input type="hidden" name="need-phone" value="false" />
				<input type="hidden" name="need-address" value="false" />
				<input type="hidden" name="label" value="What a generous person!" />
				<input type="hidden" name="targets" value="What a generous person!" />
				<button type="submit" className="donut_float">D<img src="/system_images/donut.png" alt="donut" />NUT</button>
			</form>
		);
	}
}