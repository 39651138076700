import React from 'react'
import {Link} from 'react-router-dom';

import { sendPostRequest } from "./../HELPERS/requests.js"

import Player from './../COMPONENTS/Player.js';

export default class Mix extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			MIX: props.MIX,
			TRACKLIST: false,
			DO_SHARE: false,
			CONTENT: 'TRACKLIST',
			COMMENTS: false,
			NAME: '',
			COMMENT: '',
			SENDING_COMMENT: false,
			PLAY_MOMENT: false,
			CURRENT_TIME: 0
		}

		this.setShareRef = this.setShareRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
	}

	setShareRef(node) {
        this.share = node;
    }

	componentDidMount()
	{
		document.addEventListener('mousedown', this.handleClickOutside);
	}

	componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    handleClickOutside(event) {
        if (this.share && !this.share.contains(event.target)) {
            if (this.state.DO_SHARE) {
                this.setState({DO_SHARE: false});
            }
        }
    }

	renderGenres()
	{
		if (this.state.MIX.genre_array.length) {
			let GENRES = [];
			for (let i = 0; i < this.state.MIX.genre_array.length; i++) {
				let GENRE = this.state.MIX.genre_array[i];
				GENRES.push(<Link to={'/' + GENRE.url} key={i}>{GENRE.title}</Link>);
			}
			return (
				<span>
					{GENRES}
				</span>
			);
		} else {
			return (<span>-</span>);
		}
	}

	renderSharer = () =>
	{
		let CLASS = '';
		if (this.state.DO_SHARE) {
			CLASS = ' active';
		}
		let TITLE = 'RM DA FUNK - ' + this.state.MIX.title;
		return (
			<div className={'sharer' + CLASS} ref={this.setShareRef}>
                <a rel="noopener noreferrer" target="_blank" href={'http://api.whatsapp.com/send?text=' + this.state.MIX.url}>
                    <img alt="whatsapp" src="/system_images/whatsapp.png" />
                </a>
                <a rel="noopener noreferrer" target="_blank" href={'https://telegram.me/share/url?url=' + this.state.MIX.url + '&text=' + this.state.MIX.url}>
                    <img src="/system_images/telegram.png" alt="telegram" />
                </a>
                <a rel="noopener noreferrer" target="_blank" href={'https://twitter.com/intent/tweet?text=' + this.state.MIX.url}>
                    <img src="/system_images/twitter.png" alt="twitter" />
                </a>
                <a rel="noopener noreferrer" target="_blank" href={'https://connect.ok.ru/offer?url=' + this.state.MIX.url + '&title=' + TITLE + '&description=' + this.state.MIX.url + '&imageUrl=' + this.state.MIX.image}>
                    <img src="/system_images/ok.png" alt="ok" />
                </a>
                <a rel="noopener noreferrer" target="_blank" href={'https://vk.com/share.php?url=' + this.state.MIX.url + '&title=' + TITLE + '&description=' + this.state.MIX.url + '&imageUrl=' + this.state.MIX.image}>
                    <img src="/system_images/vk.png" alt="vk" />
                </a>
                <a rel="noopener noreferrer" target="_blank" href={'https://facebook.com/sharer.php?u=' + this.state.MIX.url + '&quote=' + this.state.MIX.url}>
                    <img src="/system_images/facebook.png" alt="facebook" />
                </a>
                <a rel="noopener noreferrer" target="_blank" href={'viber://forward?text=' + this.state.MIX.url}>
                    <img src="/system_images/viber.png" alt="viber" />
                </a>
            </div>
		);
	}

	fetchComments = async (DO) =>
	{
		if ((!this.state.COMMENTS) || (typeof DO !== "undefined")) {
			try {
	            let SEND_DATA = {
	                action:     'getComments',
	                mix:      	this.state.MIX.id
	            };

	            let data = await sendPostRequest(SEND_DATA,'https://rm-da-funk.ru/api.php');

	            if (data !== false) {
	                this.setState({
	                    COMMENTS: data
	                });
	            }
	        } catch (error) {
	            console.log('BAD_MIXES_LAZY');
	        }
	    }
	}

	switchView = (VIEW) =>
	{
		if (this.state.CONTENT !== VIEW) {
			if (VIEW === 'COMMENTS') {
				this.fetchComments();
			}

			this.setState({CONTENT: VIEW});
		}
	}

	renderComments()
	{
		if (this.state.COMMENTS) {
			if (this.state.COMMENTS.length) {
				let COMMENTS = [];

				for (let i = 0; i < this.state.COMMENTS.length; i++) {
					let COMMENT = this.state.COMMENTS[i];

					let CLASS = '';
					if (COMMENT.name === 'RM DA FUNK') {
						CLASS = ' rm_da_funk'
					}
					COMMENTS.push(
						<div className="comment" key={i}>
							<div className="comment_head">
								<div className={'comment_name' + CLASS}>
									{COMMENT.name}
								</div>
								<div className="comment_date">
									<img src="/system_images/clock.png" alt="clock" />{COMMENT.date}
								</div>
								<div className="comment_ip">
									<img src="/system_images/ip.png" alt="ip" />{COMMENT.ip}
								</div>
							</div>
							<div className="comment_body">
								{COMMENT.comment}
							</div>
						</div>
					);
				}

				return (
					<div className="comments_list">
						{COMMENTS}
					</div>
				);
			} else {
				return (<div className="no_mixes"><img src="/system_images/error_black.png" alt="error"/>Комментарии пока никто не оставил!</div>);
			}
		} else {
			return (
				<div className="mixes_loader">
                	<img src="/system_images/loader.svg" alt="loader" />
                </div>
			);
		}
	}

	recordName = (text) => 
    {
        this.setState({NAME: text.target.value});
    }

    recordComment = (text) => 
    {
        this.setState({COMMENT: text.target.value});
    }

    submitComment = async () =>
    {
    	if (!this.state.SENDING_COMMENT) {
    		this.setState({SENDING_COMMENT: true});

    		try {
	            let SEND_DATA = {
	                action:     'leaveComment',
	                name: 		this.state.NAME,
	                comment: 	this.state.COMMENT,
	                mix:      	this.state.MIX.id
	            };

	            let data = await sendPostRequest(SEND_DATA,'https://rm-da-funk.ru/api.php');

	            if (data !== false) {
	                this.setState({
	                    COMMENTS: data,
	                    SENDING_COMMENT: false,
	                    NAME: '',
	                    COMMENT: ''
	                });
	            }
	        } catch (error) {
	            console.log('BAD_MIXES_LAZY');
	        }
    	}
    }

    renderButton()
    {
    	if ((this.state.NAME.length > 1) && (this.state.COMMENT.length > 3) && (!this.state.SENDING_COMMENT)) {
    		return (<span className="active_button" onClick={() => this.submitComment()}>Отправить</span>);
    	} else {
    		return (<span className="inactive_button">Отправить</span>);
    	}
    }

	renderCommentForm()
	{
		return (
			<div className="form">
				<textarea className="comment_input" onChange={(text) => this.recordComment(text)} placeholder="Комментарий" value={this.state.COMMENT}></textarea>
				<div className="form_lined">
					<input className="name_input" onChange={(text) => this.recordName(text)} placeholder="Имя" value={this.state.NAME} />
					{this.renderButton()}
				</div>
			</div>
		);
	}

	showContent()
	{
		if (this.state.CONTENT === 'TRACKLIST') {
			let tracks = this.state.MIX.tracklist.split('</p><p>');
			
			let PREPARED_TRACKS = [];
			for (let i = 0; i < tracks.length; i++) {
				let string = tracks[i].replace('<p>', '');
				string = string.replace('</p>', '');
				let parts = string.split('[');
				if (typeof parts[1] !== "undefined") {
					let parts2 = parts[1].split(']');
					if (parts2.length) {
						let time = parts2[0];
						if (time.split(':').length == 3) {
							PREPARED_TRACKS.push({
								string: string,
								time: time
							});
						}
					}
				}
			}
			
			let PLAY_ITEMS = [];
			if (PREPARED_TRACKS.length) {
				for (let i = 0; i < PREPARED_TRACKS.length; i++) {

					let NEXT_PRETTY_TIME = false;
					if (typeof PREPARED_TRACKS[i + 1] !== "undefined") {
						NEXT_PRETTY_TIME = PREPARED_TRACKS[i + 1].time;
					}
					let TRACK = PREPARED_TRACKS[i];
					PLAY_ITEMS.push(
						<p key={i}>
							<a onClick={() => this.setState({PLAY_MOMENT: TRACK.time})} className={"play_moment" + this.getThisPlayingClass(TRACK.time,NEXT_PRETTY_TIME)}>
								<img src="/system_images/play_purple.png" /> {TRACK.string}
							</a>
						</p>
					)
				}
			}

			if (PLAY_ITEMS.length) {
				return <div className="mix_tracklist_content">{PLAY_ITEMS}</div>
			} else {
				return <div className="mix_tracklist_content" dangerouslySetInnerHTML={{__html: this.state.MIX.tracklist}}></div>;
			}

			
		} else {
			return (
				<div className="mix_tracklist_content">
					{this.renderCommentForm()}
					{this.renderComments()}
				</div>
			);
		}
	}

	resetPlayMoment = () =>
	{
		this.setState({PLAY_MOMENT: false});
	}

	setCurrentPlayTime = (seconds) =>
	{
		this.setState({CURRENT_TIME: seconds})
	}

	getThisPlayingClass = (pretty_time,next_pretty_time) =>
	{
		let parts = pretty_time.split(':');
		let time = 0;
		time += parseInt(parts[0]) * 3600;
		time += parseInt(parts[1]) * 60;
		time += parseInt(parts[2]);

		let ntime = false;
		if (next_pretty_time) {
			let nparts = next_pretty_time.split(':');
			ntime = 0;
			ntime += parseInt(nparts[0]) * 3600;
			ntime += parseInt(nparts[1]) * 60;
			ntime += parseInt(nparts[2]);
		}

		if (this.state.CURRENT_TIME > time) {
			if (ntime) {
				if (ntime > this.state.CURRENT_TIME) {
					return ' playing_now';
				}
			} else {
				return ' playing_now';
			}
		}

		return '';
	}

	getButtonClass = (VIEW) =>
	{
		if (this.state.CONTENT === VIEW) {
			return 'active';
		}
		return '';
	}

	num_title = (n, titles) =>
    {
        return titles[(n % 10 === 1 && n % 100 !== 11) ? 0 : n % 10 >= 2 && n % 10 <= 4 && (n % 100 < 10 || n % 100 >= 20) ? 1 : 2];
    }

	render() {
		return (
			<section className="mix">
				<div className="mix_background" style={{backgroundImage: 'url(' + this.state.MIX.image + ')'}}></div>
				<div className="mix_data">
					<div className="mix_image_holder">
						<img src={this.state.MIX.image} className="album_art" alt={this.state.MIX.title} />
						<a href={this.state.MIX.dnl} target="_blank" rel="noopener noreferrer" className="download_button">
							<img src="/system_images/download.png" alt="download" />
							<span>Скачать</span>
						</a>
						<div className="mix_actions">
							<a className="open_button" href={this.state.MIX.url} target="_blank" rel="noopener noreferrer">
								<img src="/system_images/link.png" alt="link" />
								<span>Открыть</span>
							</a>
							<span className="share_button" onClick={() => this.setState({DO_SHARE: true})}>
								<img src="/system_images/share.png" alt="share" />
								<span>Поделиться</span>
							</span>
							{this.renderSharer()}
						</div>
						
					</div>
					<div className="mix_info_block">
						<div className="mix_title">
							RM DA FUNK - {this.state.MIX.title}
						</div>
						<div className="mix_info_row">
							<div>
								Жанр: {this.renderGenres()}
							</div>
							<div>
								Длительность: <span>{this.state.MIX.duration}</span>
							</div>
							<div>
								Размер: <span>{this.state.MIX.filesize}</span>
							</div>
							<div className="mix_statistics">
								<div><img src="/system_images/play_icon.png" alt="Прослушивания" /><span>{this.state.MIX.listens} {this.num_title(this.state.MIX.listens, ['раз', 'раза', 'раз'])}</span></div>
								<div><img src="/system_images/download.png" alt="Скачивания" /><span>{this.state.MIX.downloads} {this.num_title(this.state.MIX.downloads, ['раз', 'раза', 'раз'])}</span></div>
							</div>						  		
						</div>
						<Player 
							title={'RM DA FUNK - ' + this.state.MIX.title} 
							id={this.state.MIX.id} 
							pcm={this.state.MIX.pcm} 
							src={this.state.MIX.src} 
							next={this.state.MIX.next} 
							time={this.state.MIX.duration} 
							play_moment={this.state.PLAY_MOMENT}
							image={this.state.MIX.image}
							resetPlayMoment={this.resetPlayMoment}
							setCurrentPlayTime={this.setCurrentPlayTime}
						/>
						<div className="mix_tracklist">
							<div className="mix_tracklist_header">
								<span className={this.getButtonClass('TRACKLIST')} onClick={() => this.switchView('TRACKLIST')}>Tracklist</span>
							</div>
							{this.showContent()}
						</div>
					</div>
				</div>
				<div className="mix_year"><img alt="calendar" src="/system_images/calendar.png" />{this.state.MIX.year}</div>
			</section>
		)
	}
}

