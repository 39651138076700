import React from 'react'
import {Helmet} from 'react-helmet';

import MixesRenderer from './../COMPONENTS/MixesRenderer.js';
import { sendPostRequest } from "./../HELPERS/requests.js"

/// REDUX ///
import * as ReduxActions from './../REDUX/functions.js';
import { bindActionCreators } from 'redux';
import {connect} from 'react-redux';

function mapStateToProps(state) {
    return {
        FADED_BODY: state.FADED_BODY
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}

class MixesList extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			TOTAL_MIXES: 0,
            MIXES: false,
            START: 0,
            LOADING: false,
		}
	}

	componentDidMount() {
		this.props.setActiveTitle("RM's Audio Spot");
		this.getMixes();
	}

	getMixes = async() =>
	{
		if (!this.state.LOADING) {
			let START = this.state.START;
			let MIXES = this.state.MIXES;
			if (this.state.MIXES === false) {
				MIXES = [];
			}			
	        
	        this.setState({LOADING: true});

	        try {
	            let SEND_DATA = {
	                action:     'getMixes',
	                start:      START
	            };

	            let data = await sendPostRequest(SEND_DATA,'https://rm-da-funk.ru/api.php');

	            if (data !== false) {
	                this.setState({
	                    MIXES: MIXES.concat(data.mixes),
	                    START: START + 2,
	                    LOADING: false,
	                    TOTAL_MIXES: data.total
	                });
	            } else {
	            	this.setState({
	                    MIXES: [],
	                    LOADING: false
	                });
	            }
	        } catch (error) {
	            console.log(error);
	        }
	    }
	}

	num_title = (n, titles) =>
    {
        return titles[(n % 10 === 1 && n % 100 !== 11) ? 0 : n % 10 >= 2 && n % 10 <= 4 && (n % 100 < 10 || n % 100 >= 20) ? 1 : 2];
    }

	renderTitle()
	{
		let SHOW_AMOUNT =  this.state.TOTAL_MIXES + ' ' + this.num_title(this.state.TOTAL_MIXES, ['запись', 'записи', 'записей']);
		if (!this.state.MIXES) {
			SHOW_AMOUNT = '...';
		}
		return (
			<div className="head_title">
				({SHOW_AMOUNT})
			</div>
		);
	}

	renderMixes()
	{
		if (this.state.MIXES) {
			if (this.state.MIXES.length) {
				return (<MixesRenderer MIXES={this.state.MIXES} getMixes={this.getMixes} TOTAL_MIXES={this.state.TOTAL_MIXES} LOADING={this.state.LOADING} />);
			} else {
				return (<div className="no_mixes"><img src="/system_images/error.png" alt="error"/>Миксов нет!</div>);
			}
		} else {
			return (
				<div className="mixes_loader">
                	<img src="/system_images/loader.svg" alt="loader" />
                </div>
			);
		}
	}

	getClass = () =>
	{
		if (this.props.FADED_BODY) {
			return 'mixes_holder faded';
		} else {
			return 'mixes_holder';
		}
	}

	render() {
		return (
			<div id="mixes_list" className={this.getClass()}>
				<Helmet>
                        <title>RM's Audio Spot</title>
					    <meta name="description" content="Музыка для своих от RM DA FUNK" data-react-helmet="true" />
					    <meta name="keywords" content="Музыка для своих от RM DA FUNK" data-react-helmet="true" />

					    <meta property="og:title" content="RM's Audio Spot" />
					    <meta property="og:description" content="Музыка для своих от RM DA FUNK" />
					    <meta property="og:image" content="https://rm-da-funk.ru/system_images/logo.png" />
					    <meta property="og:type" content="article" />
					    <meta property="og:url" content= "https://rm-da-funk.ru" />
					    <meta property="og:site_name" content="RM's Audio Spot" />
                </Helmet>

				{this.renderTitle()}
				{this.renderMixes()}
			</div>
		)
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(MixesList)