const DEFAULT_TITLE = "RM's Audio Spot";

function changeFavicon(src) {
	document.head || (document.head = document.getElementsByTagName('head')[0]);
    var link = document.createElement('link');
    var oldLink = document.getElementById('dynamic-favicon');
    link.id = 'dynamic-favicon';
    link.rel = 'shortcut icon';
    link.href = src;
    if (oldLink) {
        document.head.removeChild(oldLink);
    }
    document.head.appendChild(link);
}

var initState = {
	IS_PLAYING: false,
	LOADED: false,
	TITLE: DEFAULT_TITLE,
	ACTIVE_TITLE: DEFAULT_TITLE,
	YEARS: [],
	GENRES: [],
	MIXES_YEARS: [],
	FADED_BODY: false
};

var reducer = function(state = initState, action) {
	switch (action.type) {
		case 'SET_STARTUP':
			return {
				...state,
				GENRES: action.data.genres,
				YEARS: action.data.years
			}

		case 'SET_ACTIVE_TITLE':
			return {
				...state,
				ACTIVE_TITLE: action.data
			}

		case 'FADED_BODY':
			return {
				...state,
				FADED_BODY: action.data
			}

		case 'SET_MIXES_YEARS':
			return {
				...state,
				MIXES_YEARS: action.data
			}

		case 'IS_PLAYING':
			return {
				...state,
				IS_PLAYING: action.data
			}

		case 'LOADED':
			return {
				...state,
				LOADED: action.data
			}

		case 'SET_TITLE':
			let SET_TITLE = state.TITLE;
			if (action.data) {
				if (state.TITLE !== action.data) {
					SET_TITLE = action.data;
				}
			} else {
				if (!state.IS_PLAYING) {
					SET_TITLE = state.ACTIVE_TITLE;
				}
			}

			document.querySelector('title').textContent = SET_TITLE;
			if (!state.IS_PLAYING) {
				changeFavicon('/favicon.ico');
			} else {
				console.log(action.image)
				changeFavicon(action.image);
			}

			return {
				...state,
				TITLE: SET_TITLE
			}

		default:
			return state;
	}
}

export default reducer;