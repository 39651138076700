import React, {Component} from 'react';

export default class Spinner extends Component {

    /// CONSTRUCTOR ///
    constructor(props)
    {
        super(props);
        this.state = {
        	MIXES_YEARS: props.MIXES_YEARS,
        	SPIN: props.SPIN,
        	BACKGROUND_IMAGE: false
        };
    }

    componentDidMount()
    {
    	this.startSpinner();
    }

    componentDidUpdate(prevProps, prevState, snapshot)
    {
        if (this.props.SPIN !== prevProps.SPIN) {
            this.setState({SPIN: this.props.SPIN});
        }
        if (this.props.MIXES_YEARS !== prevProps.MIXES_YEARS) {
            this.setState({MIXES_YEARS: this.props.MIXES_YEARS});
            let COMPONENT = this;
            setTimeout(function(){
                COMPONENT.startSpinner()
            }, 50);
        }
    }

    componentWillUnmount() {
    	if (typeof this.changer !== "undefined") {
			clearInterval(this.changer);
		}
	}

	startSpinner()
	{
		if ((this.state.MIXES_YEARS.length > 0) && (typeof this.changer == "undefined")) {
			let COMPONENT = this;
	    	this.changer = setInterval(() => {
	    		let min = 0;
		    	let max = COMPONENT.state.MIXES_YEARS.length - 1;
		    	let KEY = Math.floor(Math.random() * (max - min + 1)) + min;
	    		COMPONENT.setState({BACKGROUND_IMAGE: KEY});
			}, 300);
	    }
	}

    addClass = (key) =>
    {
    	if (key === this.state.BACKGROUND_IMAGE) {
    		return ' visible';
    	} else {
    		return '';
    	}
    }

	render()
    {
    	let IMAGES = [];
    	let CLASS = '';
    	if ((this.state.SPIN) && (this.state.BACKGROUND_IMAGE)) {
    		CLASS = ' visible';
    		for (let i = 0; i < this.state.MIXES_YEARS.length; i++) {
    			let SRC = this.state.MIXES_YEARS[i].image;
    			IMAGES.push(<img key={i} className={'wheel_back_image' + this.addClass(i)} src={SRC} alt="spin_image" />)
    		}
		}

        return (
            <div className={'spinner' + CLASS}>
            	<div className="spinner_wheel">
                    <div className="spinner_wheel_center"></div>
            		{IMAGES}
            	</div>
            </div>
        );
    }
}