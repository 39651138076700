import React from 'react'

/// REDUX ///
import * as ReduxActions from './../REDUX/functions.js';
import { bindActionCreators } from 'redux';
import {connect} from 'react-redux';

function mapStateToProps(state) {
    return {
        LOADED: state.LOADED,
        GENRES: state.GENRES,
        YEARS: state.YEARS
    };
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}

class Loader extends React.Component {

	constructor(props) {
		super(props)
		this.state = {
			LOADED: this.props.LOADED,
			GENRES: this.props.GENRES,
			YEARS: this.props.YEARS
		}
	}

	componentDidUpdate(prevProps, prevState, snapshot)
    {
    	if (this.props.LOADED !== prevProps.LOADED) {
    		this.setState({LOADED: this.props.LOADED});
    	}
    	if (this.props.GENRES !== prevProps.GENRES) {
    		this.setState({GENRES: this.props.GENRES});
    	}
    	if (this.props.YEARS !== prevProps.YEARS) {
    		this.setState({YEARS: this.props.YEARS});
    	}
    }

	render() {
		if ((this.state.LOADED) && (this.state.YEARS.length > 0) && (this.state.GENRES.length > 0)) {
			return false;
		} else {
			return (<div className="preloader"><img src="/system_images/logo.jpg" alt="loader" /><div className="loading_text">Ищем подходящую музыку ...</div></div>);
		}
	}
}

export default connect(
    mapStateToProps, mapDispatchToProps
)(Loader)