import React from 'react'
import ReactDOM from 'react-dom'
import WaveSurfer from 'wavesurfer.js'

/// REDUX ///
import * as ReduxActions from './../REDUX/functions.js';
import { bindActionCreators } from 'redux';
import {connect} from 'react-redux';

function mapStateToProps(state) {
    return {
        IS_PLAYING: state.IS_PLAYING
    };
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}

class Player extends React.Component {

	constructor(props) {
		super(props)
		this.state = {
			TITLE: this.props.title,
			ID: this.props.id,
			SOURCE: this.props.src,
			PCM: this.props.pcm,
			NEXT: this.props.next,
			TOTAL_TIME: this.props.time,
			IMAGE: this.props.image,
			PLAYING: false,
			CURRENT_TIME: '00:00:00',
			LOADED: false
		}
		this.HEIGHT = this.getHeight();
		this.SEEK = false;
		this.NORMAL_PAUSE = false;
		this.NORMAL_PLAY = false;
	}

	getHeight = () =>
	{
		let WIDTH = window.innerWidth;
		let HEIGHT = 128;
		if (WIDTH < 800) {
			HEIGHT = 50;
		}

		return HEIGHT;
	}

	updateSize = () =>
	{
		let HEIGHT = this.getHeight();
		if (HEIGHT !== this.HEIGHT) {
			this.HEIGHT = HEIGHT;
			this.wavesurfer.setHeight(HEIGHT);
		}
	}

	componentDidMount() {
		window.addEventListener('resize', this.updateSize);

		this.startWaveSurfer();
	}

	startWaveSurfer = () =>
	{
		this.el = ReactDOM.findDOMNode(this)
		this.waveform = this.el.querySelector('#wave_' + this.state.ID)

		this.wavesurfer = WaveSurfer.create({
			container: this.waveform,
			height: this.getHeight(),
			waveColor: '#333333',
			cursorColor: '#000000',
			progressColor: '#692a98',
			mediaType:'audio',
			backend: 'MediaElement',
			dragSelection: false,
			partialRender: true,
			responsive: true,
			barWidth: 2,
			barRadius: 1,
			barGap: 1
		});

		this.wavesurfer.load(this.state.SOURCE, this.state.PCM, 'none');

		this.launchListeners();
	}

	launchListeners = () =>
	{
		let PLAYER = this;
		this.wavesurfer.on('ready', function () {
		    PLAYER.props.waveLoaded(true);
		    PLAYER.setState({LOADED: true});
		});

		this.wavesurfer.on('audioprocess', function () {
			PLAYER.setState({CURRENT_TIME: PLAYER.secondsToHms(PLAYER.wavesurfer.getCurrentTime())});
			PLAYER.props.setCurrentPlayTime(PLAYER.wavesurfer.getCurrentTime());
		});

		this.wavesurfer.on('pause', function () {
			if (!PLAYER.NORMAL_PAUSE) {
				if (!PLAYER.SEEK) {
					PLAYER.setState({PLAYING: false});
					PLAYER.props.isPlaying(false);
					PLAYER.props.setTitle(false,false);
				}
			} else {
				PLAYER.NORMAL_PAUSE = false;
			}
		});

		this.wavesurfer.on('seek', function () {
			PLAYER.SEEK = true;
		});

		this.wavesurfer.on('play', function () {
			if (!PLAYER.NORMAL_PLAY) {
				if (!PLAYER.SEEK) {
					PLAYER.setState({PLAYING: true});
					PLAYER.props.isPlaying(PLAYER.state.ID);
					PLAYER.props.setTitle(PLAYER.state.TITLE, PLAYER.state.IMAGE);
				} else {
					PLAYER.SEEK = false;
				}
			} else {
				PLAYER.NORMAL_PLAY = false;
			}
		});

		this.wavesurfer.on('finish', function () {
			PLAYER.setState({PLAYING: false});
			if (PLAYER.state.NEXT > 0) {
				PLAYER.props.isPlaying(PLAYER.state.NEXT);
			} else {
				PLAYER.props.isPlaying(false);
			}
		});
	}

	componentDidUpdate(prevProps, prevState, snapshot)
    {
    	if (!this.props.IS_PLAYING) {
    		if (this.wavesurfer.isPlaying()) {
	    		this.pause(true);
	    	}
    	} else {
	        if (this.props.IS_PLAYING !== prevProps.IS_PLAYING) {
	        	if (this.props.IS_PLAYING !== this.state.ID) {
		        	if (this.wavesurfer.isPlaying()) {
			            this.pause(true);
		            }
		        } else {
		        	if (!this.wavesurfer.isPlaying()) {
			            this.play();
		            }
		        }
	        }
	    }

		if (this.props.play_moment !== prevProps.play_moment) {
			if (this.props.play_moment) {
				this.props.resetPlayMoment();

				let parts = this.props.play_moment.split(':');
				let time = 0;
				time += parseInt(parts[0]) * 3600;
				time += parseInt(parts[1]) * 60;
				time += parseInt(parts[2]);

            	this.play(time);
			}
        }
    }

    secondsToHms = (d) => {
	    d = Number(d);
	    var h = Math.floor(d / 3600);
	    var m = Math.floor(d % 3600 / 60);
	    var s = Math.floor(d % 3600 % 60);

	    if (m<10) {
	    	m = '0' + m;
	    }
	    if (h<10) {
	    	h = '0' + h;
	    }
	    if (s<10) {
	    	s = '0' + s;
	    }

	    return h + ':' + m + ':' + s; 
	}

	play = (time) =>
	{	
		if (time == undefined) {
			time = 0
		}
		this.NORMAL_PLAY = true;
		this.setState({PLAYING: true});
		this.props.isPlaying(this.state.ID);
		this.props.setTitle(this.state.TITLE, this.state.IMAGE);
		this.wavesurfer.play(time);
	}

	pause = (NO_RESET) =>
	{
		this.NORMAL_PAUSE = true;
		this.setState({PLAYING: false});

		if (!NO_RESET) {
			this.props.isPlaying(false);
			this.props.setTitle(false);
		}

		this.wavesurfer.pause();
	}

	renderPlayButton()
	{
		if (this.state.PLAYING) {
			return (
				<span className="play_button playing" onClick={() => this.pause()}>
					<img src="/system_images/pause.png" alt="play" />
				</span>
			);
		} else {
			return (
				<span className="play_button" onClick={() => this.play()}>
					<img src="/system_images/play.png" alt="pause" />
				</span>
			);
		}
	}

	renderLoader()
	{
		if (!this.state.LOADED) {
			return (<div className="player_loader"><img src="/system_images/loader.svg" alt="loader" /></div>);
		}
	}

	render() {
		return (
			<div className="player_holder">
				{this.renderPlayButton()}
				
				<div className="waveform">
					{this.renderLoader()}
					<div id={'wave_' + this.state.ID} className="wave"></div>
				</div>

				<div className="mix_timing">
					{this.state.CURRENT_TIME} / {this.state.TOTAL_TIME}
				</div>
			</div>
		)
	}
}

export default connect(
    mapStateToProps, mapDispatchToProps
)(Player)