import React from 'react'
import {Helmet} from 'react-helmet';

import MixesRenderer from './../COMPONENTS/MixesRenderer.js';
import { sendPostRequest } from "./../HELPERS/requests.js"

/// REDUX ///
import * as ReduxActions from './../REDUX/functions.js';
import { bindActionCreators } from 'redux';
import {connect} from 'react-redux';

function mapStateToProps(state) {
    return {
        FADED_BODY: state.FADED_BODY
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}

class MixesListByGenre extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			TOTAL_MIXES: 0,
            MIXES: false,
            START: 0,
            LOADING: false,
            GENRE: props.QUERY,
            GENRE_TITLE: false
		}
	}

	componentDidMount() {
		this.getMixes();
	}

	componentDidUpdate(prevProps, prevState, snapshot)
    {
        if (this.props.QUERY !== prevProps.QUERY) {
            this.setState({
				TOTAL_MIXES: 0,
	            MIXES: false,
	            START: 0,
	            LOADING: false,
	            GENRE: this.props.QUERY,
            	GENRE_TITLE: false
            });

            let COMPONENT = this;
            setTimeout(function(){
                COMPONENT.getMixes()
            }, 50);
        }
    }

	getMixes = async() =>
	{
		if (!this.state.LOADING) {
			let START = this.state.START;
			let MIXES = this.state.MIXES;
			if (this.state.MIXES === false) {
				MIXES = [];
			}			
	        
	        this.setState({LOADING: true});

	        if (typeof this.interval !== "undefined") {
		        clearInterval(this.interval);
		    }

	        try {
	            let SEND_DATA = {
	                action:     'getMixesByGenre',
	                genre: 		this.state.GENRE,
	                start:      START
	            };

	            let data = await sendPostRequest(SEND_DATA,'https://rm-da-funk.ru/api.php');

	            if (data !== false) {
	            	this.props.setActiveTitle(data.genre_title);
	                this.setState({
	                    MIXES: MIXES.concat(data.mixes),
	                    START: START + 2,
	                    LOADING: false,
	                    TOTAL_MIXES: data.total,
	                    GENRE_TITLE: data.genre_title
	                });
	            } else {
	            	this.props.setActiveTitle(this.state.GENRE);
	            	this.setState({
	                    MIXES: [],
	                    START: 0,
	                    LOADING: false,
	                    TOTAL_MIXES: 0,
	                    GENRE_TITLE: false
	                });
	            }
	        } catch (error) {
	            console.log(error);
	        }
	    }
	}

	getTitle()
	{
		if (this.state.GENRE_TITLE) {
			return this.state.GENRE_TITLE;
		} else {
			return this.state.GENRE;
		}
	}

	num_title = (n, titles) =>
    {
        return titles[(n % 10 === 1 && n % 100 !== 11) ? 0 : n % 10 >= 2 && n % 10 <= 4 && (n % 100 < 10 || n % 100 >= 20) ? 1 : 2];
    }

	renderTitle()
	{
		let SHOW_AMOUNT =  this.state.TOTAL_MIXES + ' ' + this.num_title(this.state.TOTAL_MIXES, ['запись', 'записи', 'записей']);
		if (!this.state.MIXES) {
			SHOW_AMOUNT = '...';
		}
		return (
			<div className="head_title">
				в жанре <span>{this.getTitle()}</span> ({SHOW_AMOUNT})
			</div>
		);
	}

	renderMixes()
	{
		if (this.state.MIXES) {
			if (this.state.MIXES.length) {
				return (<MixesRenderer MIXES={this.state.MIXES} getMixes={this.getMixes} TOTAL_MIXES={this.state.TOTAL_MIXES} LOADING={this.state.LOADING} />);
			} else {
				return (<div className="no_mixes"><img src="/system_images/error.png" alt="error"/>Миксов нет!</div>);
			}
		} else {
			return (
				<div className="mixes_loader">
                	<img src="/system_images/loader.svg" alt="loader" />
                </div>
			);
		}
	}

	renderHelmet = () =>
	{
		let HELMET = (
			<Helmet>
                <title>{this.getTitle()}</title>
			    <meta name="description" content={this.getTitle()} data-react-helmet="true" />
			    <meta name="keywords" content={this.getTitle()} data-react-helmet="true" />

			    <meta property="og:title" content={this.getTitle()} />
			    <meta property="og:description" content={this.getTitle()} />
			    <meta property="og:image" content="https://rm-da-funk.ru/system_images/logo.png" />
			    <meta property="og:type" content="article" />
			    <meta property="og:url" content= {'https://rm-da-funk.ru/' + this.state.GENRE} />
			    <meta property="og:site_name" content="RM's Audio Spot" />
            </Helmet>
        );


		return HELMET;
	}

	getClass = () =>
	{
		if (this.props.FADED_BODY) {
			return 'mixes_holder faded';
		} else {
			return 'mixes_holder';
		}
	}

	render() {
		return (
			<div id="mixes_list" className={this.getClass()}>
				{this.renderHelmet()}
				{this.renderTitle()}
				{this.renderMixes()}
			</div>
		)
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(MixesListByGenre)