import React from 'react'
import {Link, withRouter} from 'react-router-dom';

import { sendPostRequest } from "./../HELPERS/requests.js"

/// REDUX ///
import * as ReduxActions from './../REDUX/functions.js';
import { bindActionCreators } from 'redux';
import {connect} from 'react-redux';

import Spinner from './../COMPONENTS/Spinner.js'

function mapStateToProps(state) {
    return {
    	MIXES_YEARS: state.MIXES_YEARS,
        GENRES: state.GENRES,
        YEARS: state.YEARS
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}

class Header extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			MIXES_YEARS: props.MIXES_YEARS,
			SHOW_LIST: false,
			URL: props.URL,
			EMAIL: '',
			COLORS: [
				'#652993',
				'#d4850e',
				'#d40e69',
				'#0ea6d4',
				'#3ac329',
				'#e2fd02',
				'#ca1111',
				'#363e5a'
			],
			ACTIVE: 0,
			YEARS: false,
			GENRES: false,
			SHOW_NAV: false,
			SHOW_YEAR: false,
			SHOW_GENRE: false,
			SPIN: false
		}
	}

	componentDidMount()
	{
		this.getYearsAndGenres();
		this.getMixesAndYears();
	}

	getMixesAndYears = async () =>
	{
		if (this.state.MIXES_YEARS.length === 0) {
			try {
	            let SEND_DATA = {
	                action:     'getMixesAndYears'
	            };

	            let data = await sendPostRequest(SEND_DATA,'https://rm-da-funk.ru/api.php');

	            if (data !== false) {
	                this.props.setMixesAndYears(data.mixes);
	            }
	        } catch (error) {
	            console.log('BAD_START_UP');
	        }
		}
	}

	componentDidUpdate(prevProps, prevState, snapshot)
    {
        if (this.props.URL !== prevProps.URL) {
            this.setState({
				URL: this.props.URL
            });
        }
        if (this.props.MIXES_YEARS !== prevProps.MIXES_YEARS) {
            this.setState({
				MIXES_YEARS: this.props.MIXES_YEARS
            });
        }
    }

	getYearsAndGenres = async() =>
	{
		if ((this.props.GENRES.length === 0) && (this.props.YEARS.length === 0)) {
	        try {
	            let SEND_DATA = {
	                action:     'startUp'
	            };

	            let data = await sendPostRequest(SEND_DATA,'https://rm-da-funk.ru/api.php');

	            if (data !== false) {
	                this.props.setStartUp(data);
	                this.setState({
	                	GENRES: data.genres,
	                	YEARS: data.years
	                });
	                this.props.waveLoaded(true);
	            }
	        } catch (error) {
	            console.log('BAD_START_UP');
	        }
	    } else {
	    	this.setState({
            	GENRES: this.props.GENRES,
            	YEARS: this.props.YEARS
            });
	    }
	}

	onHover = () =>
    {
    	let TOTAL = this.state.COLORS.length - 1;
    	let SET_ACTIVE = this.state.ACTIVE + 1;
    	if (SET_ACTIVE > TOTAL) {
    		SET_ACTIVE = 0;
    	}

    	this.setState({ACTIVE: SET_ACTIVE});
    }

    getCurrentColor = () =>
    {
    	return this.state.COLORS[this.state.ACTIVE];
    }

    renderGenres(MIX)
	{
		if (MIX.genre_array.length) {
			let GENRES = [];
			for (let i = 0; i < MIX.genre_array.length; i++) {
				let GENRE = MIX.genre_array[i];
				GENRES.push(<span key={i}>{GENRE.title}</span>);
			}
			return (
				<span>
					{GENRES}
				</span>
			);
		} else {
			return (<span>-</span>);
		}
	}

    renderList()
    {
    	
    		let YEARS = [];
    		let CURRENT_YEAR = 0;

    		for (let i = 0; i < this.state.MIXES_YEARS.length; i++) {
    			let MIX = this.state.MIXES_YEARS[i];

    			if (CURRENT_YEAR !== MIX.year) {
    				CURRENT_YEAR = MIX.year;
    				YEARS.push(<div key={'heading_' + MIX.year} className="year_heading">{MIX.year}</div>);
				}

				let TITLE = 'RM DA FUNK - ' + MIX.title;
				YEARS.push(
					<Link to={MIX.inner_url} className="quick_mix" key={MIX.id} onClick={() => this.setState({SHOW_LIST: false, SHOW_NAV: false})}>
						<div className="quick_mix_image">
							<img src={MIX.image} alt={TITLE} />
						</div>
						<div className="quick_mix_info">
							<div className="quick_mix_title">
								{TITLE}
							</div>
							<div className="quick_mix_small_info">
								Жанр: {this.renderGenres(MIX)}
							</div>
						</div>
					</Link>
				);
    		}

    		let CLASS = '';
    		if (this.state.SHOW_LIST) {
    			CLASS = ' opened';
			}

    		return (
    			<div className={'modal_list' + CLASS}>
    				<span className="modal_close" onClick={() => this.setState({SHOW_LIST: false})}>
    					<img src="/system_images/close.png" alt="close" />
    				</span>
    				{YEARS}
    			</div>
			);
    }

    renderSpinningWheel()
    {
   		return (<Spinner MIXES_YEARS={this.state.MIXES_YEARS} SPIN={this.state.SPIN} />);
    }

    loadRandom = () =>
    {
    	this.setState({SPIN: true});
    	let min = 0;
    	let max = this.state.MIXES_YEARS.length - 1;
    	let KEY = Math.floor(Math.random() * (max - min + 1)) + min;

    	let COMPONENT = this;
    	setTimeout(function(){
            COMPONENT.props.history.push(COMPONENT.state.MIXES_YEARS[KEY].inner_url);
            COMPONENT.closeNav();
        }, 100);
    	setTimeout(function(){
            COMPONENT.setState({SPIN: false});
        }, 3000);
    }

    renderSelects()
    {
    	if ((this.state.GENRES) && (this.state.YEARS)) {
    		let GENRES = [];
    		GENRES.push(<Link onClick={() => this.closeNav()} key="all" to={'/'}>Все жанры</Link>);
    		for (let i = 0; i < this.props.GENRES.length; i++) {
    			let GENRE = this.props.GENRES[i];

    			let GENRE_CLASS = '';
    			if (this.state.URL !== "undefined") {
	    			if (this.state.URL === GENRE.url) {
	    				GENRE_CLASS = 'selected';
	    			}
	    		}

    			GENRES.push(<Link onClick={() => this.closeNav()} className={GENRE_CLASS} key={i} to={'/' + GENRE.url}>{GENRE.title}</Link>)
    		}
    		let YEARS = [];
    		YEARS.push(<Link onClick={() => this.closeNav()} key="all" to={'/'}>Все года</Link>);
    		for (let i = 0; i < this.props.YEARS.length; i++) {
    			let YEAR = this.props.YEARS[i];

    			let YEAR_CLASS = '';
    			if (this.state.URL !== "undefined") {
	    			if (parseInt(this.state.URL) === parseInt(YEAR.year)) {
	    				YEAR_CLASS = 'selected';
	    			}
	    		}
    			
    			YEARS.push(<Link onClick={() => this.closeNav()} className={YEAR_CLASS} key={i} to={'/' + YEAR.year}>{YEAR.year}</Link>)
    		}

    		let NAV_CLASS = '';
    		if (this.state.SHOW_NAV) {
    			NAV_CLASS = ' opened';
    		}

    		let YEAR_CLASS = '';
    		if (this.state.SHOW_YEAR) {
    			YEAR_CLASS = ' opened';
    		}

    		let GENRE_CLASS = '';
    		if (this.state.SHOW_GENRE) {
    			GENRE_CLASS = ' opened';
    		}

    		return (
    			<div className={'head_selects' + NAV_CLASS}>
					<span className="the_select list_select" onClick={() => this.loadRandom()}>
    					<span className="the_select_head"><img src="/system_images/dice.png" alt="list" />Случайный выбор</span>
    				</span>
    				<span className="the_select list_select" onClick={() => this.setState({SHOW_LIST: true})}>
    					<span className="the_select_head"><img src="/system_images/list.png" alt="list" />Список</span>
    				</span>
    				<div className="the_select">
    					<span onClick={() => this.setState({SHOW_GENRE: true})} className="the_select_head">Выбрать жанр<img src="/system_images/arrow.png" alt="arrow" /></span>
    					<div className={'the_select_drop' + GENRE_CLASS}>
    						{GENRES}
						</div>
					</div>
					<div className="the_select">
						<span onClick={() => this.setState({SHOW_YEAR: true})} className="the_select_head">Выбрать год<img src="/system_images/arrow.png" alt="arrow" /></span>
						<div className={'the_select_drop' + YEAR_CLASS}>
    						{YEARS}
						</div>
					</div>
    			</div>
			);
    	}
    }

    closeNav = () =>
    {
    	this.setState({SHOW_NAV: false});
    }

    toggleSelects = () =>
    {
    	if (this.state.SHOW_NAV) {
    		this.setState({SHOW_NAV: false});
    	} else {
    		this.setState({SHOW_NAV: true});
    	}
    }

    renderNavButton = () =>
    {
    	if (this.state.SHOW_NAV) {
    		return (<img src="/system_images/close.png" alt="nav" />);
    	} else {
    		return (<img src="/system_images/nav.png" alt="nav" />);
    	}
    }

	render() {
		return (
			<section className="header">
				<span className="nav_toggle" onClick={() => this.toggleSelects()}>
					{this.renderNavButton()}
				</span>
				<Link to="/" className="header_h1_link">
					<h1 style={{color: this.getCurrentColor()}} onMouseOver={this.onHover}>Музыка для своих от RM DA FUNK</h1>
				</Link>
				{this.renderSelects()}
				{this.renderList()}
				{this.renderSpinningWheel()}
			</section>
		)
	}
}

export default withRouter(connect(
    mapStateToProps, mapDispatchToProps
)(Header))