export const isPlaying = (data) => {
	return {
		type: 'IS_PLAYING',
		data: data
	}
}

export const waveLoaded = (data) => {
	return {
		type: 'LOADED',
		data: data
	}
}

export const setActiveTitle = (data) => {
	return {
		type: 'SET_ACTIVE_TITLE',
		data: data
	}
}

export const setTitle = (data, image) => {
	return {
		type: 'SET_TITLE',
		data: data,
		image: image
	}
}

export const setStartUp = (data) => {
	return {
		type: 'SET_STARTUP',
		data: data
	}
}

export const setMixesAndYears = (data) => {
	return {
		type: 'SET_MIXES_YEARS',
		data: data
	}
}

export const setBodyFaded = (data) => {
	return {
		type: 'FADED_BODY',
		data: data
	}
}