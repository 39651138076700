import React from 'react';
import { Provider } from 'react-redux';
import { createStore } from 'redux';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import reducer from './REDUX/reducer.js';

import ScrollTop from './COMPONENTS/ScrollTop.js';
import Index from './COMPONENTS/Index.js';
import SubRoute from './COMPONENTS/SubRoute.js';
import Donut from './COMPONENTS/Donut.js';

const store = createStore(reducer);

export default class App extends React.Component {
	constructor(props)
    {
    	super(props);
		this.myRef = React.createRef();
	}

	scrollToTop = () =>
	{
		this.myRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
	}

	render()
	{
		return (
			<div ref={this.myRef}>
				<Provider store={store}>
					<BrowserRouter>
						<Switch>
							<Route exact path='/' component={Index}/>
							<Route exact path='/:sub_route' component={SubRoute}/>	
						</Switch>
					</BrowserRouter>
					<ScrollTop scrollToTop={this.scrollToTop} />
				</Provider>
				<Donut />
			</div>
		);
	}
}
