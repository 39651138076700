import React from 'react'

import Mix from './../COMPONENTS/Mix.js';

export default class MixesRenderer extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			TOTAL_MIXES: props.TOTAL_MIXES,
			MIXES: props.MIXES,
			LOADING: props.LOADING
		}

		this.myRef = React.createRef();
	}

	componentDidMount() {
		document.addEventListener('scroll', this.trackScrolling);
	}

	componentWillUnmount() {
		document.removeEventListener('scroll', this.trackScrolling);
	}

	componentDidUpdate(prevProps, prevState, snapshot)
    {
        if (this.props.MIXES !== prevProps.MIXES) {
            this.setState({MIXES: this.props.MIXES, TOTAL_MIXES: this.props.TOTAL_MIXES});
        }
        if (this.props.LOADING !== prevProps.LOADING) {
            this.setState({LOADING: this.props.LOADING});
        }
    }

	isBottom(el) {
		return el.getBoundingClientRect().bottom - 600 <= window.innerHeight;
	}

	trackScrolling = () => {
		const wrappedElement = document.getElementById('mixes_list');
		if (this.isBottom(wrappedElement)) {
			let GET = true;
			if (this.state.TOTAL_MIXES > 0) {
				if (parseInt(this.state.TOTAL_MIXES) <= this.state.MIXES.length) {
					GET = false;
				}
			}

			if (GET) {
				this.props.getMixes();
			}
		}
	};

	renderMixes()
	{
		if (this.state.MIXES) {
			if (this.state.MIXES.length) {
				let MIXES = [];
				for (let i = 0; i < this.state.MIXES.length; i++) {
					let MIX = this.state.MIXES[i];

					let NEXT = 0;
					if (typeof this.state.MIXES[i + 1] !== "undefined") {
						NEXT = this.state.MIXES[i + 1].id;
					}
					MIX.next = NEXT;

					MIXES.push(<Mix key={i} MIX={MIX} />);
				}
				return (
					<div>
						{MIXES}
					</div>
				);
			}
		}
	}

	renderMore = () =>
	{
		if (this.state.LOADING) {
			return (
                <div className="mixes_loader">
                	<img src="/system_images/loader.svg" alt="loader" />
                </div>
            );
		} else {
			if (parseInt(this.state.TOTAL_MIXES) > this.state.MIXES.length) {
	            return (
	                <span className="load_more" onClick={() => this.props.getMixes()}>
	                	Загрузить ещё?
	                </span>
	            );
	        }
	    }
	}

	render() {
		return (
			<div>
				{this.renderMixes()}
				{this.renderMore()}
			</div>
		)
	}
}