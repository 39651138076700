import React from 'react';

import MixesList from './../COMPONENTS/MixesList.js';
import Header from './../COMPONENTS/Header.js';
import Loader from './../COMPONENTS/Loader.js';

export default class Index extends React.Component {
	constructor(props)
    {
    	super(props);
		this.myRef = React.createRef();
	}

	scrollToTop = () =>
	{
		this.myRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
	}

	render()
	{
		return (
			<div ref={this.myRef}>
				<Loader />
				<Header scrollToTop={this.scrollToTop} />
				<MixesList />
			</div>
		);
	}
}
